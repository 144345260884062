<template>
  <div>
    <modal-padrao
      ref="modalValoresAuxiliares"
      :titulo="$t('modulos.certificado_terceiro.formulario.valores_auxiliar')"
      :mostra-botao-salvar="false"
      :titulo-cancelar="$t('geral.botoes.fechar')"
    >
      <div v-if="!!linhaValoresAuxiliares">
        <v-form
          class="row"
          :disabled="visualizar"
        >
          <input-text
            ref="inputValoresAuxiliares-1"
            v-model="linhaValoresAuxiliares.valorTexto1"
            class="col-12"
            :label="
              $t(
                'modulos.certificado_terceiro.formulario.valores.tabela.valor_aux',
                { valor: 1 }
              )
            "
            @enter="tabAuxiliar(1)"
          />
          <input-text
            ref="inputValoresAuxiliares-2"
            v-model="linhaValoresAuxiliares.valorTexto2"
            class="col-12"
            :label="
              $t(
                'modulos.certificado_terceiro.formulario.valores.tabela.valor_aux',
                { valor: 2 }
              )
            "
            @enter="tabAuxiliar(2)"
          />
          <input-text
            ref="inputValoresAuxiliares-3"
            v-model="linhaValoresAuxiliares.valorTexto3"
            class="col-12"
            :label="
              $t(
                'modulos.certificado_terceiro.formulario.valores.tabela.valor_aux',
                { valor: 3 }
              )
            "
            @enter="tabAuxiliar(3)"
          />
          <input-text
            ref="inputValoresAuxiliares-4"
            v-model="linhaValoresAuxiliares.valorTexto4"
            class="col-12"
            :label="
              $t(
                'modulos.certificado_terceiro.formulario.valores.tabela.valor_aux',
                { valor: 4 }
              )
            "
            @enter="tabAuxiliar(4)"
          />
          <input-text
            ref="inputValoresAuxiliares-5"
            v-model="linhaValoresAuxiliares.valorTexto5"
            class="col-12"
            :label="
              $t(
                'modulos.certificado_terceiro.formulario.valores.tabela.valor_aux',
                { valor: 5 }
              )
            "
            @enter="tabAuxiliar(5)"
          />
        </v-form>
      </div>
    </modal-padrao>
    <div class="mt-6 d-flex align-center justify-end">
      <div class="d-flex align-center">
        <botao-padrao
          class="ml-2"
          color="secondary"
          :tooltip="
            $t(
              'modulos.verificacao_intermediaria.valores.botoes.tooltip.conversor_unidades'
            )
          "
          outlined
          :disabled="visualizar"
          @click="abrirConversorDeUnidadeDeMedida"
        >
          <v-icon> $calculatorVariantOutline </v-icon>
        </botao-padrao>
      </div>
    </div>
    <v-form
      ref="tabela-valores"
      :disabled="visualizar"
    >
      <v-simple-table class="mt-3 tabela-dinamica-calibracao">
        <thead>
          <tr>
            <th scope="rol" />
            <th scope="rol">
              {{
                $t(
                  'modulos.certificado_terceiro.formulario.valores.tabela.ponto'
                )
              }}
            </th>
            <th scope="rol">
              {{
                $t(
                  'modulos.certificado_terceiro.formulario.valores.tabela.erro'
                )
              }}
            </th>
            <th scope="rol">
              {{
                $t(
                  'modulos.certificado_terceiro.formulario.valores.tabela.incerteza'
                )
              }}
            </th>
            <th scope="rol">
              {{
                $t(
                  'modulos.certificado_terceiro.formulario.valores.tabela.veff'
                )
              }}
            </th>
            <th scope="rol">
              {{
                $t(
                  'modulos.certificado_terceiro.formulario.valores.tabela.fatorK'
                )
              }}
            </th>
            <th scope="rol">
              {{
                $t(
                  'modulos.certificado_terceiro.formulario.valores.tabela.descricao'
                )
              }}
            </th>
          </tr>
        </thead>

        <tbody class="linhas">
          <tr
            v-for="linha in linhasFiltradas"
            :key="linha.instrumentoFaixaPontoId"
          >
            <td>
              <dropdown-padrao
                text
                color="secondary"
              >
                <template #botao>
                  <v-icon> $dotsVertical </v-icon>
                </template>
                <dropdown-padrao-item @click="valoresAuxiliar(linha)">
                  {{
                    $t(
                      'modulos.certificado_terceiro.formulario.valores_auxiliar'
                    )
                  }}
                </dropdown-padrao-item>
              </dropdown-padrao>
            </td>
            <td>{{ linha.pontoCalibracao | formatarNumeroComPrecisao }}</td>
            <td>
              <input-calibracao
                :ref="`inputOrdem${linha.indiceLinha}-0`"
                v-model="linha.erro"
                :sistema="linha.unidadeMedidaSistema"
                class="flex-fill input-tabela"
                style="width: 150px"
                background-color="transparent"
                selecionar-texto-on-focus
                :obrigatorio="form.flagPadrao"
                prevent-tab
                @tab="tabularInput(linha.indiceLinha, 0, linha.ultimaLinha)"
                @shit-tab="
                  tabularInput(linha.indiceLinha, 0, linha.ultimaLinha, true)
                "
              />
            </td>
            <td>
              <input-calibracao
                :ref="`inputOrdem${linha.indiceLinha}-1`"
                v-model="linha.incerteza"
                :sistema="linha.unidadeMedidaSistema"
                class="flex-fill input-tabela"
                style="width: 150px"
                background-color="transparent"
                selecionar-texto-on-focus
                :obrigatorio="form.flagPadrao"
                prevent-tab
                @shit-tab="
                  tabularInput(linha.indiceLinha, 1, linha.ultimaLinha, true)
                "
                @tab="tabularInput(linha.indiceLinha, 1, linha.ultimaLinha)"
              />
            </td>
            <td>
              <input-calibracao
                :ref="`inputOrdem${linha.indiceLinha}-2`"
                v-model="linha.veff"
                style="width: 150px"
                class="flex-fill input-tabela"
                background-color="transparent"
                :regras-personalizadas="regraVeff(linha.veff)"
                selecionar-texto-on-focus
                :obrigatorio="form.flagPadrao"
                prevent-tab
                @shit-tab="
                  tabularInput(linha.indiceLinha, 2, linha.ultimaLinha, true)
                "
                @tab="tabularInput(linha.indiceLinha, 2, linha.ultimaLinha)"
              />
            </td>
            <td>
              <input-calibracao
                :ref="`inputOrdem${linha.indiceLinha}-3`"
                v-model="linha.fatorK"
                style="width: 150px"
                class="flex-fill input-tabela"
                background-color="transparent"
                selecionar-texto-on-focus
                :obrigatorio="form.flagPadrao"
                prevent-tab
                @shit-tab="
                  tabularInput(linha.indiceLinha, 3, linha.ultimaLinha, true)
                "
                @tab="tabularInput(linha.indiceLinha, 3, linha.ultimaLinha)"
              />
            </td>
            <td>
              <input-calibracao
                :ref="`inputOrdem${linha.indiceLinha}-4`"
                v-model="linha.descricao"
                sistema="Sexagesimal"
                nao-aplicar-mascara
                style="width: 150px"
                background-color="transparent"
                class="flex-fill input-tabela"
                selecionar-texto-on-focus
                prevent-tab
                @tab="tabularInput(linha.indiceLinha, 4, linha.ultimaLinha)"
                @shit-tab="
                  tabularInput(linha.indiceLinha, 4, linha.ultimaLinha, true)
                "
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        v-if="visualizar"
        outlined
        color="secondary"
        class="ml-2"
        @click="cancelar"
      >
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
      <template v-else>
        <botao-padrao
          outlined
          color="secondary"
          class="ml-2"
          @click="cancelar"
        >
          {{ $t('geral.botoes.cancelar') }}
        </botao-padrao>
        <botao-padrao
          class="ml-2"
          @click="salvar"
        >
          {{ $t('geral.botoes.salvar') }}
        </botao-padrao>
        <botao-padrao
          v-if="form.id"
          class="ml-2"
          @click="confirmacaoFinalizar"
        >
          {{ $t('geral.botoes.finalizar') }}
        </botao-padrao>
      </template>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { InputCalibracao } from '@components/inputs';
export default {
  components: { InputCalibracao },
  props: ['form', 'faixaSelecionada', 'faixas', 'visualizar'],
  data: function () {
    return {
      colunas: [
        'Valor Aux 1',
        'Valor Aux 2',
        'Valor Aux 3',
        'Valor Aux 4',
        'Valor Aux 5',
      ],
      linhaValoresAuxiliares: null,
    };
  },
  computed: {
    linhasFiltradas: function () {
      let linhasFiltradas = this.faixas.filter(
        (el) => el.value == this.faixaSelecionada
      );

      if (linhasFiltradas.length == 0) return [];

      let { linhas } = linhasFiltradas[0];

      return _.sortBy(linhas, 'pontoCalibracao');
    },
  },
  watch: {
    faixaSelecionada: function () {
      this.$nextTick(() => {
        this.$refs[`inputOrdem0-0`][0].focus();
      });
    },
  },
  methods: {
    abrirConversorDeUnidadeDeMedida: function () {
      window.open(
        this.$router.resolve({ name: 'conversor-unidade' }).href,
        '_blank'
      );
    },
    valoresAuxiliar: function (linha) {
      this.linhaValoresAuxiliares = linha;
      this.$refs.modalValoresAuxiliares.abrirModal();
      this.$nextTick(() => {
        this.$refs[`inputValoresAuxiliares-1`].focus();
      });
    },

    confirmacaoSalvar: function () {
      if (this.form.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },

    confirmacaoFinalizar: function () {
      this.$emit('confirmacao-finalizar');
    },

    salvar: function () {
      this.$emit('atualizar-linhas');
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name:this.$route.query?.retorno || 'certificado-terceiro' });
      });
    },
    tabularInput: function (
      indiceLinha,
      indiceColuna,
      ultimaLinha,
      shift = false
    ) {
      const atualizarIndiceColuna = shift ? indiceColuna - 1 : indiceColuna + 1;

      if (indiceColuna == 4 && indiceLinha === ultimaLinha && !shift) {
        this.$emit('mudar-faixa');
        return;
      }
      if (shift && indiceColuna == 0 && indiceLinha == 0) {
        return;
      }
      if (shift && indiceColuna == 0) {
        this.$refs[`inputOrdem${indiceLinha - 1}-4`][0].focus();
        return;
      }
      if (indiceColuna == 4 && !shift) {
        this.$refs[`inputOrdem${indiceLinha + 1}-0`][0].focus();
        return;
      }

      this.$refs[
        `inputOrdem${indiceLinha}-${atualizarIndiceColuna}`
      ][0].focus();
    },
    tabAuxiliar: function (indiceValorAuxiliar, shift = false) {
      const atualizarValorAuxiliar = shift
        ? indiceValorAuxiliar - 1
        : indiceValorAuxiliar + 1;

      if (indiceValorAuxiliar == 0 && shift) {
        return;
      }
      if (indiceValorAuxiliar == 5 && !shift) {
        return;
      }
      this.$refs[`inputValoresAuxiliares-${atualizarValorAuxiliar}`].focus();
    },
    regraVeff: function (linhaVeff) {
      if (!linhaVeff) return [];

      let regraValor = (v) => {
        if (v > 1000) {
          return this.$t('modulos.certificado_terceiro.validacao.veff');
        }
        return true;
      };

      let regraNaoPodeDecimal = (v) => {
        if (v % 1 != 0) {
          return this.$t(
            'modulos.certificado_terceiro.validacao.nao_pode_decimal'
          );
        }
        return true;
      };
      return [regraValor, regraNaoPodeDecimal];
    },
    validarValores: function () {
      return this.$refs['tabela-valores'].validate();
    },
  },
};
</script>
<style lang="scss">
.tabela-dinamica-calibracao {
  th {
    background-color: $color-primary-grey-100;
  }
  .coluna-propriedades {
    width: 0;
  }
  .linhas tr:first-child td {
    border-top: 2px solid;
  }
}
</style>
