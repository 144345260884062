var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"form",staticClass:"row mt-2"},[_c('input-text',{staticClass:"col-12 col-md-2",attrs:{"label":_vm.$t('modulos.certificado_terceiro.formulario.dados_certificado.codigo'),"obrigatorio":"","max":20,"disabled":_vm.visualizar},model:{value:(_vm.form.codigoCertificado),callback:function ($$v) {_vm.$set(_vm.form, "codigoCertificado", $$v)},expression:"form.codigoCertificado"}}),_c('input-cliente',{staticClass:"col-12 col-md-2",attrs:{"label":_vm.$t(
          'modulos.certificado_terceiro.formulario.dados_certificado.cliente'
        ),"obrigatorio":"","disabled":_vm.visualizar ||
          !_vm.participanteLogadoConta ||
          !!_vm.form.id ||
          _vm.bloquearInstrumentoCliente},model:{value:(_vm.form.cliente),callback:function ($$v) {_vm.$set(_vm.form, "cliente", $$v)},expression:"form.cliente"}}),(_vm.participanteLogadoConta)?_c('input-ordem-servico',{staticClass:"col-12 col-md-2",attrs:{"obrigatorio":"","label":_vm.$t(
          'modulos.certificado_terceiro.formulario.dados_certificado.ordem_servico'
        ),"multiplos":false,"disabled":_vm.visualizar ||
          !_vm.participanteLogadoConta ||
          _vm.bloquearOrdemServico ||
          _vm.bloquearInstrumentoCliente,"instrumento-id":_vm.form.instrumento?.value},model:{value:(_vm.form.ordemServico),callback:function ($$v) {_vm.$set(_vm.form, "ordemServico", $$v)},expression:"form.ordemServico"}}):_vm._e(),_c('input-instrumento',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t(
          'modulos.certificado_terceiro.formulario.dados_certificado.instrumento'
        ),"placeholder":_vm.$t('geral.inputs.selecione'),"obrigatorio":"","somente-ativos":"","cliente-selecionado":_vm.form.cliente,"disabled":_vm.visualizar ||
          !!_vm.form.id ||
          !_vm.form.cliente?.value ||
          _vm.bloquearInstrumentoCliente,"multiplos":false},model:{value:(_vm.form.instrumento),callback:function ($$v) {_vm.$set(_vm.form, "instrumento", $$v)},expression:"form.instrumento"}}),_c('input-laboratorio',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t(
          'modulos.certificado_terceiro.formulario.dados_certificado.laboratorio'
        ),"placeholder":_vm.$t('geral.inputs.selecione'),"obrigatorio":"","multiplos":false,"flag-fornecedor-laboratorio":"","disabled":_vm.visualizar,"mostra-botao-atualiza-listagem":true},model:{value:(_vm.form.laboratorio),callback:function ($$v) {_vm.$set(_vm.form, "laboratorio", $$v)},expression:"form.laboratorio"}}),_c('input-date',{staticClass:"col-12 col-md-2",attrs:{"label":_vm.$t(
          'modulos.certificado_terceiro.formulario.dados_certificado.data_calibracao'
        ),"obrigatorio":"","disabled":_vm.visualizar,"nudge":"-50px","apenas-datas-passadas":""},model:{value:(_vm.form.dataCalibracao),callback:function ($$v) {_vm.$set(_vm.form, "dataCalibracao", $$v)},expression:"form.dataCalibracao"}}),_c('input-text',{class:{
        'col-12': !_vm.participanteLogadoConta,
        'col-12 col-md-10': _vm.participanteLogadoConta,
      },attrs:{"label":_vm.$t('modulos.certificado_terceiro.formulario.dados_certificado.obs'),"disabled":_vm.visualizar},model:{value:(_vm.form.observacao),callback:function ($$v) {_vm.$set(_vm.form, "observacao", $$v)},expression:"form.observacao"}})],1),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"d-flex align-center justify-end"},[(_vm.visualizar)?_c('botao-padrao',{staticClass:"ml-2",attrs:{"outlined":"","color":"secondary"},on:{"click":_vm.cancelar}},[_vm._v(" "+_vm._s(_vm.$t('geral.botoes.voltar'))+" ")]):[_c('botao-padrao',{staticClass:"ml-2",attrs:{"outlined":"","color":"secondary"},on:{"click":_vm.cancelar}},[_vm._v(" "+_vm._s(_vm.$t('geral.botoes.cancelar'))+" ")]),_c('botao-padrao',{staticClass:"ml-2",on:{"click":_vm.confirmacaoSalvar}},[_vm._v(" "+_vm._s(_vm.$t('geral.botoes.salvar'))+" ")]),(!!_vm.form.id)?_c('botao-padrao',{staticClass:"ml-2",on:{"click":_vm.confirmacaoFinalizar}},[_vm._v(" "+_vm._s(_vm.$t('geral.botoes.finalizar'))+" ")]):_vm._e()]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }