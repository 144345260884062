<template>
  <modal-padrao
    ref="modal-linhas-erros"
    max-width="80%"
    :titulo="$t('modulos.certificado_terceiro.linhas_modal.titulo')"
    titulo-cancelar="Fechar"
    :mostra-botao-salvar="false"
  >
    <strong class="d-flex mt-2 mb-1">
      {{ textoModal[tipoModal] }}
    </strong>
    <div
      v-for="(item, index) in linhasComErros"
      :key="index"
    >
      <accordion-padrao
        :alterar-cor="true"
        cor-azul-texto
        :titulo="`${item.tabelaNome}`"
        class="accordion-padrao mb-5"
      >
        <div
          v-for="(linha, indexLinha) in item.linhas"
          :key="indexLinha"
          class="d-flex"
        >
          <span class="ml-10">
            {{ linha.pontoCalibracao }} - {{ linha.unidadeMedida.nome }}
          </span>
        </div>
      </accordion-padrao>
    </div>
  </modal-padrao>
</template>
<script>
export default {
  data() {
    return {
      linhasComErros: [],
      textoModal: {
        Finalizar: this.$t(
          'modulos.certificado_terceiro.linhas_modal.mensagem_finalizar'
        ),
        Salvar: this.$t(
          'modulos.certificado_terceiro.linhas_modal.mensagem_salvar'
        ),
      },
      tipoModal: '',
    };
  },
  methods: {
    abrirModal: function (linhas, tipoModal) {
      this.tipoModal = tipoModal;
      this.linhasComErros = linhas.reduce((acc, linha) => {
        const buscarTabela = acc.find((item) => {
          return item.tabelaId === linha.tabelaId;
        });

        if (buscarTabela) {
          buscarTabela.linhas.push(linha);
        } else {
          acc.push({
            tabelaId: linha.tabelaId,
            tabelaNome: linha.tabela,
            linhas: [linha],
          });
        }

        return acc;
      }, []);
      this.$refs['modal-linhas-erros'].abrirModal();
    },
  },
};
</script>
<style>
.accordion-padrao {
  color: #0d47a1;
}
</style>
