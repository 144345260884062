<template>
  <div>
    <v-form
      ref="form"
      class="row mt-2"
    >
      <input-text
        v-model="form.codigoCertificado"
        class="col-12 col-md-2"
        :label="
          $t('modulos.certificado_terceiro.formulario.dados_certificado.codigo')
        "
        obrigatorio
        :max="20"
        :disabled="visualizar"
      />
      <input-cliente
        v-model="form.cliente"
        class="col-12 col-md-2"
        :label="
          $t(
            'modulos.certificado_terceiro.formulario.dados_certificado.cliente'
          )
        "
        obrigatorio
        :disabled="
          visualizar ||
            !participanteLogadoConta ||
            !!form.id ||
            bloquearInstrumentoCliente
        "
      />
      <input-ordem-servico
        v-if="participanteLogadoConta"
        v-model="form.ordemServico"
        obrigatorio
        class="col-12 col-md-2"
        :label="
          $t(
            'modulos.certificado_terceiro.formulario.dados_certificado.ordem_servico'
          )
        "
        :multiplos="false"
        :disabled="
          visualizar ||
            !participanteLogadoConta ||
            bloquearOrdemServico ||
            bloquearInstrumentoCliente
        "
        :instrumento-id="form.instrumento?.value"
      />
      <input-instrumento
        v-model="form.instrumento"
        class="col-12 col-md-3"
        :label="
          $t(
            'modulos.certificado_terceiro.formulario.dados_certificado.instrumento'
          )
        "
        :placeholder="$t('geral.inputs.selecione')"
        obrigatorio
        somente-ativos
        :cliente-selecionado="form.cliente"
        :disabled="
          visualizar ||
            !!form.id ||
            !form.cliente?.value ||
            bloquearInstrumentoCliente
        "
        :multiplos="false"
      />
      <input-laboratorio
        v-model="form.laboratorio"
        class="col-12 col-md-3"
        :label="
          $t(
            'modulos.certificado_terceiro.formulario.dados_certificado.laboratorio'
          )
        "
        :placeholder="$t('geral.inputs.selecione')"
        obrigatorio
        :multiplos="false"
        flag-fornecedor-laboratorio
        :disabled="visualizar"
        :mostra-botao-atualiza-listagem="true"
      />
      <input-date
        v-model="form.dataCalibracao"
        class="col-12 col-md-2"
        :label="
          $t(
            'modulos.certificado_terceiro.formulario.dados_certificado.data_calibracao'
          )
        "
        obrigatorio
        :disabled="visualizar"
        nudge="-50px"
        apenas-datas-passadas
      />
      <input-text
        v-model="form.observacao"
        :class="{
          'col-12': !participanteLogadoConta,
          'col-12 col-md-10': participanteLogadoConta,
        }"
        :label="
          $t('modulos.certificado_terceiro.formulario.dados_certificado.obs')
        "
        :disabled="visualizar"
      />
    </v-form>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        v-if="visualizar"
        outlined
        color="secondary"
        class="ml-2"
        @click="cancelar"
      >
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
      <template v-else>
        <botao-padrao
          outlined
          color="secondary"
          class="ml-2"
          @click="cancelar"
        >
          {{ $t('geral.botoes.cancelar') }}
        </botao-padrao>
        <botao-padrao
          class="ml-2"
          @click="confirmacaoSalvar"
        >
          {{ $t('geral.botoes.salvar') }}
        </botao-padrao>
        <botao-padrao
          v-if="!!form.id"
          class="ml-2"
          @click="confirmacaoFinalizar"
        >
          {{ $t('geral.botoes.finalizar') }}
        </botao-padrao>
      </template>
    </div>
  </div>
</template>
<script>
import {
InputCliente,
InputInstrumento,
InputLaboratorio,
InputOrdemServico,
} from '@/components/inputs';
export default {
  components: {
    InputInstrumento,
    InputLaboratorio,
    InputCliente,
    InputOrdemServico,
  },
  props: {
    form: {},
    participanteLogadoConta: {
      type: Boolean,
      default: false,
    },
    visualizar: {
      type: Boolean,
      default: false,
    },
    bloquearInstrumentoCliente: {
      type: Boolean,
      default: false,
    },
    bloquearOrdemServico: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirmacaoSalvar: function () {
      if (!this.$refs.form.validate()) return;
      this.salvar();
    },

    salvar: function () {
      this.$emit('salvar-dados');
    },

    confirmacaoFinalizar: function () {
      this.$emit('confirmacao-finalizar');
    },

    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name:this.$route.query?.retorno || 'certificado-terceiro' });
      });
    },
    validar: function () {
      return this.$refs.form.validate();
    },
  },
};
</script>

