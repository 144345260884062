<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <div>
        <botao-padrao
          v-if="podeExcluir"
          class="my-2"
          outlined
          color="danger"
          @click="excluirRegistros"
        >
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div>
      <botao-padrao
        v-if="!visualizar"
        class="mt-3"
        @click="anexarArquivo"
      >
        {{ $t('modulos.calibracao.anexos.botoes.anexar_arquivo') }}
      </botao-padrao>
    </div>

    <tabela-padrao
      ref="tabela"
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :mostrar-checkbox="!visualizar"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      @paginar="listarRegistros"
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template
        v-slot:item.acoes="{ item }"
      >
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <botao-padrao

              text
              color="secondary"
              @click="download(item.item)"
            >
              {{ $t('geral.botoes.download') }}
            </botao-padrao>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        v-if="visualizar"
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
      <botao-padrao
        v-else
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
    </div>
    <modal-anexos
      ref="modalAnexo"
      @listar="atualizarTabela"
    />
  </div>
</template>
<script>
import CertificadoTerceiroService from '@common/services/cadastros/CertificadoTerceiroService';
//import ModalAnexos from './ModalAnexos.vue';
import ModalAnexos from './ModalAnexos.vue';
import helpers from '@common/utils/helpers';
export default {
  components: {
    ModalAnexos,
  },
  props: {
    calibracaoId: {},
    visualizar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
            width: '50px',
          },
          {
            value: 'descricao',
            text: this.$t(
              'modulos.certificado_terceiro.formulario.anexos.formulario.descricao'
            ),
            sortable: true,
          },
          {
            value: 'nomeArquivo',
            text: this.$t(
              'modulos.certificado_terceiro.formulario.anexos.formulario.nome_arquivo'
            ),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  computed: {
    podeExcluir() {
      return this.tabela.selecionados.length > 0;
    },
  },
  mounted() {
    this.listarRegistros();
  },
  methods: {
    atualizarTabela: async function () {
      await this.listarRegistros();

      if (this.tabela.quantidadeItens % this.tabela.porPagina == 1) {
        this.tabela.paginaAtual =
          parseInt(this.tabela.quantidadeItens / this.tabela.porPagina) + 1;

        this.$refs.tabela.alterarPagina(
          this.tabela.paginaAtual,
          this.tabela.porPagina
        );
      }
    },

    listarRegistros: async function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      await CertificadoTerceiroService.listarAnexos(
        this.calibracaoId,
        parametros
      )
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        const listaIdExclusao = this.tabela.selecionados.map((s) => s.id);
        CertificadoTerceiroService.excluirAnexos(
          this.calibracaoId,
          listaIdExclusao
        )
          .then(() => {
            if (
              this.tabela.dados.length == listaIdExclusao.length &&
              this.tabela.paginaAtual > 1
            )
              this.tabela.paginaAtual--;

            this.$refs.tabela.alterarPagina(this.tabela.paginaAtual);

            this.toastSucesso(
              this.$t(`modulos.calibracao.anexos.exclusao_sucesso`)
            );
            this.tabela.selecionados = [];
            this.listarRegistros()
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    anexarArquivo: function () {
      this.$refs['modalAnexo'].abrirModal(this.calibracaoId);
    },
    download: function (item) {
      helpers.baixarBase64(item.arquivo, item.nomeArquivo);
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({
          name: this.$route.query?.retorno || 'certificado-terceiro',
        });
      });
    },
  },
};
</script>
