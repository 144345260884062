
<template>
  <div>
    <div class="d-flex align-start justify-space-between mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}

        <div>
          <v-chip
            v-if="form.id"
            class="mr-1"
            small
          >
            Código: {{ form.codigoCertificado }}
          </v-chip>
          <template v-if="participanteLogadoConta">
            <v-chip
              class="mr-1"
              small
            >
              Instrumento: {{ badges?.instrumento }}
            </v-chip>
            <v-chip
              class="mr-1"
              small
            >
              Tipo do Instrumento: {{ badges?.tipoInstrumento }}
            </v-chip>
            <v-chip
              class="mr-1"
              small
            >
              Ordem de Serviço: {{ badges?.ordemServico }}
            </v-chip>
          </template>
        </div>
      </div>
      <input-select
        v-if="indiceAba == 1"
        v-model="faixaSelecionada"
        class="w-30"
        :label="$t('modulos.verificacao_intermediaria.formulario.abas.faixas')"
        :placeholder="$t('geral.inputs.selecione')"
        :options="faixas"
        em-filtro
        :clearable="false"
      />
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
      @change="salvarAoMudar"
    >
      <v-tab>
        {{
          $t('modulos.certificado_terceiro.formulario.abas.dados_certificado')
        }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.certificado_terceiro.formulario.abas.valores') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.certificado_terceiro.formulario.abas.anexo') }}
      </v-tab>
      <v-tab v-if="exibirDerivas">
        {{ $t('modulos.calibracao.formulario.abas.derivas') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="indiceComponentes">
      <v-tab-item>
        <dados-certificado
          ref="dados"
          :bloquear-instrumento-cliente="bloquearInstrumentoCliente"
          :participante-logado-conta="participanteLogadoConta"
          :form="form"
          :visualizar="ehVisualizacao"
          :bloquear-ordem-servico="bloquearOrdemServico"
          @salvar-dados="salvarDados"
          @confirmacao-finalizar="finalizar"
        />
      </v-tab-item>
      <v-tab-item>
        <valores
          ref="valores"
          :form="form"
          :faixa-selecionada="faixaSelecionada"
          :faixas="faixas"
          :visualizar="ehVisualizacao"
          @atualizar-linhas="atualizarLinhas"
          @confirmacao-finalizar="finalizar"
          @mudar-faixa="mudarFaixa"
        />
      </v-tab-item>
      <v-tab-item>
        <anexos
          :calibracao-id="form.id"
          :visualizar="ehVisualizacao"
        />
      </v-tab-item>
      <v-tab-item>
        <derivas
          :calibracao-id="id"
          :instrumento-id="form.instrumento?.value"
          :visualizacao="true"
          :alterar-titulo-pagina-visualizar-certificado="true"
        />
      </v-tab-item>
    </v-tabs-items>
    <v-divider
      v-if="indiceAba == 3"
      class="my-5"
    />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        v-if="indiceAba == 3"
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
    </div>
    <linhas-sem-valor-modal ref="linhas-sem-valor-modal" />
  </div>
</template>

<script>
import { CertificadoTerceiroModel } from '@/common/models/cadastros/CertificadoTerceiroModel.js';
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import {
CertificadoTerceiroService,
ParticipanteService,
} from '@common/services';
import helpers from '@common/utils/helpers';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import Derivas from '../certificados/definir_derivas';
import Anexos from './components/Anexos.vue';
import DadosCertificado from './components/DadosCertificado';
import Valores from './components/Valores.vue';
import LinhasSemValorModal from './components/modais/LinhasSemValorModal';
export default {
  components: {
    DadosCertificado,
    Valores,
    Anexos,
    Derivas,
    LinhasSemValorModal,
  },
  props: ['id', 'proximaAba'],
  data() {
    return {
      indiceAba: 0,
      indiceComponentes: 0,
      form: new CertificadoTerceiroModel({}),
      formControle: new CertificadoTerceiroModel({}),
      faixas: [],
      pontosCalibracao: [],
      faixaSelecionada: null,
      abasLiberadas: {
        tabelas: false,
        valores: false,
      },
      badges: {
        instrumento: null,
        tipoInstrumento: null,
        ordemServico: null,
      },
      bloquearOrdemServico: false,
    };
  },
  computed: {
    ...mapGetters('Autenticacao', [
      'participanteLogadoConta',
      'participanteSelecionado',
    ]),
    tituloFormulario: function () {
      if (this.$route.name == 'certificado-terceiro-visualizar')
        return this.$t('modulos.certificado_terceiro.titulos.visualizar');
      if (this.id)
        return this.$t('modulos.certificado_terceiro.titulos.editar');
      return this.$t('modulos.certificado_terceiro.titulos.novo');
    },
    ehVisualizacao: function () {
      return this.$route.name == 'certificado-terceiro-visualizar';
    },
    exibirDerivas: function () {
      return (
        this.$route.name == 'certificado-terceiro-visualizar' &&
        this.form.situacaoCertificado == 'Assinado'
      );
    },
    bloquearInstrumentoCliente: function () {
      return !!(
        this.$route.query?.instrumentoId ||
        this.$route.query.clienteParticipanteId ||
        this.$route.query.ordemServicoId
      );
    },
  },
  watch: {
    id: async function () {
      if (this.id) await this.buscarCertificadoTerceiro();
    },
    faixaSelecionada: function () {
      this.form.faixaSelecionada = this.faixas.find(
        (faixa) => faixa.value == this.faixaSelecionada
      );
    },
  },
  async mounted() {
    helpers.redirecionarSemPermissao(this,'CertificadoTerceiro', 'Editar');
    helpers.redirecionarSemPermissao(this, 'CertificadoTerceiro', 'Inserir');
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.certificado_terceiro.titulos.listagem')
    );
    if (this.$route.name == 'certificado-terceiro-visualizar') {
      this.$store.dispatch(
        'Layout/alterarTituloPagina',
        this.$t('modulos.certificados.titulos.visualizar_certificado')
      );
    }
    this.verificarParticipanteConta();
    if (this.id) await this.buscarCertificadoTerceiro();

    if (
      this.$route.query?.instrumentoId &&
      this.$route.query?.clienteParticipanteId &&
      this.$route.query?.ordemServicoId
    ) {
      const {
        instrumentoId,
        clienteParticipanteId,
        ordemServicoId,
        ordemServicoCodigo,
        instrumentoCodigo,
        clienteParticipanteNome,
        tipoInstrumentoNome,
      } = this.$route.query;

      this.form.instrumento = {
        value: instrumentoId,
        text: instrumentoCodigo,
      };
      this.form.cliente = {
        value: clienteParticipanteId,
        text: clienteParticipanteNome,
      };

      this.form.ordemServico = {
        value: ordemServicoId,
        text: ordemServicoCodigo,
      };

      this.badges = {
        instrumento: instrumentoCodigo,
        tipoInstrumento: tipoInstrumentoNome,
        ordemServico: ordemServicoCodigo,
      };
    }
  },
  methods: {
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({
          name: this.$route.query?.retorno || 'certificado-terceiro',
          params: { id: this.$route.query?.instrumentoRetorno || null },
        });
      });
    },
    salvarAoMudar() {
      if (this.$route.name == 'certificado-terceiro-visualizar') {
        this.indiceComponentes = this.indiceAba;
        return;
      }
      this.form.faixasAtualizadas = this.faixas;

      if (this.veffMaiorQue1000OuDecimal()) {
        this.toastAlerta(
          this.$t('modulos.certificado_terceiro.validacao.veff_1000_decimal')
        );
        setTimeout(() => {
          this.indiceAba = this.indiceComponentes;
        }, 100);
        return;
      }
      let alterado = true;
      switch (this.indiceComponentes) {
        case 0:
        case 1:
          alterado = this.form.id
            ? !_.isEqual(this.formControle, this.form)
            : true;
          break;
        default:
          alterado = false;
          break;
      }
      if (alterado) {
        let valido = false;
        let funcao = null;
        switch (this.indiceComponentes) {
          case 0:
            valido = this.$refs.dados.validar();
            funcao = this.salvarDados;
            break;
          case 1:
            valido = true;
            funcao = this.atualizarLinhas;
            break;
          default:
            valido = true;
            break;
        }
        if (!valido) {
          this.$nextTick(() => {
            this.indiceAba = this.indiceComponentes;
          });
          return;
        }
        if (!funcao) {
          this.indiceComponentes = this.indiceAba;
          return;
        }
        funcao(this.indiceAba);
      } else {
        this.indiceComponentes = this.indiceAba;
      }
    },
    buscarCertificadoTerceiro: async function (acao) {
      this.$store.dispatch('Layout/iniciarCarregamento', 'Carregando Dados...');

      await CertificadoTerceiroService.buscarCertificado(this.id)
        .then((res) => {
          this.form = new CertificadoTerceiroModel({
            id: this.id,
            ...res.data,
          });
          if(this.form.ordemServico?.value) this.bloquearOrdemServico = true;

          this.badges = {
            instrumento: this.form.instrumento?.text,
            tipoInstrumento: this.form.tipoInstrumento?.text,
            ordemServico: this.form.ordemServico?.text,
          };
          this.formControle = _.cloneDeep(this.form);

          this.faixas = this.form.faixas.map((faixa) => {
            const linhasTabuladas = [];
            const faixaOrdenada = _.sortBy(faixa.linhas, 'pontoCalibracao');
            for (let i = 0; i < faixa.linhas.length; i++) {
              linhasTabuladas.push({
                ...faixaOrdenada[i],
                indiceLinha: i,
                ultimaLinha: faixa.linhas.length - 1,
              });
            }
            return {
              value: faixa.instrumentoFaixaId,
              text: faixa.nomeFaixa,
              linhas: linhasTabuladas,
              unidadeMedida: faixa.unidadeMedida,
              calibracaoFaixaId: faixa.calibracaoFaixaId,
            };
          });
          if (this.faixas.length > 0)
            this.faixaSelecionada = this.faixas[0].value;

          if (!this.proximaAba) return;
          this.indiceComponentes = this.proximaAba;
          this.indiceAba = this.proximaAba;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
      if (acao) {
        this.$nextTick(() => (this.indiceAba += 1));
      }
    },
    salvarDados: function (proximaAba = 0) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      CertificadoTerceiroService.salvar(this.form)
        .then((res) => {
          this.toastSucesso(
            this.$t(`modulos.certificado_terceiro.cadastro_sucesso`)
          );

          if (!this.form.id) {
            this.$router.push({
              name: 'certificado-terceiro-editar',
              params: { id: res.data, proximaAba: proximaAba },
              query: this.$route.query?.retorno,
            });
            return;
          }
          this.indiceComponentes = proximaAba;
          this.indiceAba = proximaAba;
          this.$store.dispatch('Layout/terminarCarregamento');
        })
        .catch(() => {
          this.indiceAba = this.indiceComponentes;
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    temPropriedadeSemValor: function (obj) {
      const propriedadesVerificar = ['veff', 'erro', 'fatorK', 'incerteza'];
      return propriedadesVerificar.some(
        (propriedade) =>
          obj[propriedade] === undefined ||
          obj[propriedade] === null ||
          obj[propriedade] === ''
      );
    },

    verificarSeTemLinhaNaoPreenchida: function (tipoModal) {
      const linhasSemValor = [];
      if (this.form.faixasAtualizadas) {
        this.form.faixasAtualizadas.forEach((faixa) => {
          faixa.linhas.forEach((linha) => {
            if (this.temPropriedadeSemValor(linha))
              linhasSemValor.push({
                tabela: faixa.text,
                tabelaId: faixa.value,
                pontoCalibracao: linha.pontoCalibracao,
                unidadeMedida: faixa.unidadeMedida,
              });
          });
        });
      }
      if (linhasSemValor.some((item) => item)) {
        this.$refs['linhas-sem-valor-modal'].abrirModal(
          linhasSemValor,
          tipoModal
        );
         setTimeout(() => {
          this.indiceAba = this.indiceComponentes;
        }, 100);
        return true;
      }
    },

    atualizarLinhas: function (proximaAba = 1) {
      this.form.faixasAtualizadas = this.faixas;


      if (this.veffMaiorQue1000OuDecimal()) {
        this.toastAlerta(
          this.$t('modulos.certificado_terceiro.validacao.veff_1000_decimal')
        );
        return;
      }
      this.$store.dispatch('Layout/iniciarCarregamento');
      CertificadoTerceiroService.atualizarLinhas(this.form)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.certificado_terceiro.cadastro_sucesso`)
          );
          this.indiceComponentes = proximaAba;
          this.indiceAba = proximaAba;
        })
        .finally(() => {
          this.indiceAba = this.indiceComponentes;
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    finalizar: function () {
      this.form.faixasAtualizadas = this.faixas;
      if (this.verificarSeTemLinhaNaoPreenchida('Finalizar')) return;
      let valoresValido = true;
      if (this.indiceAba !== 1 && this.indiceComponentes !== 1) {
        this.indiceAba = 1;
        this.indiceComponentes = 1;
      }
      const ctx = this;
      setTimeout(() => {
        if (ctx.$refs.valores)
          valoresValido = ctx.$refs.valores.validarValores();
        if (!valoresValido) {
          this.toastAlerta(
            this.$t('modulos.certificado_terceiro.validacao.veff_1000_decimal')
          );
          return;
        }
        if (this.veffMaiorQue1000OuDecimal()) {
          this.toastAlerta(
            this.$t('modulos.certificado_terceiro.validacao.veff_1000_decimal')
          );
          return;
        }
        ctx.$store.dispatch('Layout/iniciarCarregamento');
        CertificadoTerceiroService.salvar(ctx.form)
          .then(() =>
            CertificadoTerceiroService.atualizarLinhas(ctx.form).then(() =>
              CertificadoTerceiroService.finalizar(ctx.form.id).then(() => {
                ctx.toastSucesso(
                  ctx.$t(`modulos.certificado_terceiro.finalizar_sucesso`)
                );
                ctx.$router.push({
                  name: this.$route.query.retorno || 'certificado-terceiro',
                });
              })
            )
          )
          .finally(() => {
            ctx.$store.dispatch('Layout/terminarCarregamento');
          });
      }, 100);
    },

    verificarParticipanteConta: async function () {
      if (!this.participanteLogadoConta) {
        this.$store.dispatch('Layout/iniciarCarregamento');
        ParticipanteService.buscar(this.participanteSelecionado)
          .then((res) => {
            this.form.cliente = new DropdownModel(res.data);
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      }
    },
    mudarFaixa: function () {
      const indiceAtual =
        this.faixas.findIndex((faixa) => faixa.value == this.faixaSelecionada) +
        1;
      if (indiceAtual == this.faixas.length) return;
      this.faixaSelecionada = this.faixas[indiceAtual].value;
    },
    veffMaiorQue1000OuDecimal: function () {
      const veffMaiorQue1000 = this.form.faixasAtualizadas.some((faixa) =>
        faixa.linhas.some((linha) => {
          return (
            linha.veff > 1000 ||
            !(Number(linha.veff) % 1 === 0) ||
            linha.veff?.toString().includes('.') ||
            linha.veff?.toString().includes(',')
          );
        })
      );

      return veffMaiorQue1000;
    },
  },
};
</script>
