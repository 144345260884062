import { DropdownModel } from '../geral/DropdownModel';
import _ from 'lodash';
export class CertificadoTerceiroModel {
  constructor({
    id,
    codigoCertificado,
    instrumento,
    dataCalibracao,
    laboratorio,
    observacao,
    usuarioTecnicoExecutante,
    registroFinalizado,
    situacaoCertificado,
    faixas,
    cliente,
    faixasAtualizadas = [],
    flagPadrao,
    ordemServico,
    tipoInstrumento,
  }) {
    this.id = id;
    this.codigoCertificado = codigoCertificado;
    this.instrumento = instrumento;
    if (instrumento) this.instrumento = new DropdownModel(instrumento);
    this.dataCalibracao = dataCalibracao;
    this.laboratorio = laboratorio;
    if (laboratorio) this.laboratorio = new DropdownModel(laboratorio);
    this.observacao = observacao;
    this.usuarioTecnicoExecutante = usuarioTecnicoExecutante;
    if (usuarioTecnicoExecutante)
      this.usuarioTecnicoExecutante = new DropdownModel(
        usuarioTecnicoExecutante
      );
    this.registroFinalizado = registroFinalizado;
    this.situacaoCertificado = situacaoCertificado;
    this.faixas = faixas;
    this.cliente = cliente
    if (cliente) this.cliente = new DropdownModel(cliente)
    this.faixasAtualizadas = faixasAtualizadas
    this.flagPadrao = flagPadrao
    this.ordemServico = ordemServico
    if (ordemServico) this.ordemServico = new DropdownModel(ordemServico, 'codigo')
    this.tipoInstrumento = tipoInstrumento
    if (tipoInstrumento) this.tipoInstrumento = new DropdownModel(tipoInstrumento)

  }
  get dadosCertificado() {
    let retorno = _.cloneDeep(this);
    retorno.codigo = retorno.codigoCertificado;
    retorno.instrumentoId = retorno.instrumento?.value;
    retorno.laboratorioId = retorno.laboratorio?.value;
    retorno.clienteId = retorno.cliente?.value;
    retorno.ordemServicoId = retorno.ordemServico?.value;
    return retorno;
  }

  get atualizarLinhas() {
    let retorno = _.cloneDeep(this);
    retorno.faixas = retorno.faixasAtualizadas.map((faixa) => {
      console.log(faixa)
      return {
        instrumentoFaixaId: faixa.value,
        calibracaoFaixaId: faixa.calibracaoFaixaId,
        linhas: faixa.linhas,
      }
    })
    return retorno;
  }
}
